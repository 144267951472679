.loader {
  width: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  fill: var(--text-base);
}

.loader #left_S,.loader #left_M,.loader #left_L,
.loader #right_S,.loader #right_M,.loader #right_L {
  fill: var(--tertiary-color);
}

.loader #left_S {
  animation: wavy-loader 2s .1s infinite;
}

.loader #left_M {
  animation: wavy-loader 2s .2s infinite;
}

.loader #left_L {
  animation: wavy-loader 2s .3s infinite;
}

.loader #right_S {
  animation: wavy-loader 2s .1s infinite;
}

.loader #right_M {
  animation: wavy-loader 2s .2s infinite;
}

.loader #right_L {
  animation: wavy-loader 2s .3s infinite;
}

@keyframes wavy-loader {
  0%    { opacity: 0 }
  100%  { opacity: 1 }
}
