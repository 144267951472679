.message {
  text-align: center;
}

.message img {
  width: 100px;
  margin-bottom: 12px;
}

.message h2 {
  font-size: 24px;
  margin-bottom: 8px;
}

.message p {
  text-align: center;
}
