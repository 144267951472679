@font-face {
  font-family: "Montserrat-Regular";
  src: url("../fonts/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("../fonts/Montserrat/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../fonts/Montserrat/Montserrat-SemiBold.ttf");
}

:root {
  --primary-color: #d8c4d9;
  --secondary-color: #0d99d7;
  --tertiary-color: #00bfb3;
  --error-color: #dc3545;
  --text-base: #23366f;
  --text-invert: #fff;
  --font-regular: "Montserrat-Regular";
  --font-semibold: "Montserrat-SemiBold";
  --font-bold: "Montserrat-Bold";
}

:focus {
  outline: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat-Regular";
  color:  var(--text-base);
  background-color: var(--primary-color);
  font-size: 16px;
  margin: 0;
}

a {
  font-family: var(--font-semibold);
  color: var(--secondary-color);
  text-decoration: none;
}

a:hover {
  color: var(--tertiary-color);
}

p, h1, h2, h3, h4, h5, h6, ul {
  margin: 0;
}

h2 {
  font-size: 18px;
}

strong {
  font-family: "Montserrat-SemiBold";
}

img {
  max-width: 100%;
  margin-bottom: 32px;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
}

a:hover {
  text-decoration: none;
}

.logo {

}

.logo-v {
  height: 70vh;
  margin-left: auto;
  margin-right: auto;
  padding-right: 32px;
  display: block;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-size: cover;
}

.inner {
  max-width: 758px;
  margin: auto;
  background: var(--primary-color); /*#39459b;*/
  border: 0px solid rgba(255,255,255,0.3);
  padding: 77px 99px 87px;
}

h3 {
  text-transform: uppercase;
  font-size: 45px;
  font-family: "Montserrat-Bold";
  text-align: left;
  margin-bottom: 12px;
}

p {
  text-align: left;
  padding: 0 0px;
  margin-bottom: 24px;
  line-height: 1.8;
}


@media (max-width: 767px) {
  h3 {
    font-size: 38px;
  }

  p {
    font-size: 16px;
    padding: 0;
  }

  .inner {
    padding: 27px 20px 37px;
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
  }

  .wrapper {
    background: #39459b;
    border: 0px solid;
  }
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {
}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}
